import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';

import {useLocation, useParams} from "react-router";
import {getQuicksightData} from "../../services/API";
import CircularProgress from "@mui/material/CircularProgress";
import {Grid, Typography} from "@mui/material";

let HEURISTIC = "HEURISTIC"
let STITCHING_VIEW = "STITCHING_VIEW"
let VOR_LOG = "VOR_LOG"
let CORRECTED_ERR = "CORRECTED_ERR"
let RESPONSE = "RESPONSE"
let ST_PACKAGE = "ST_PACKAGE"
let ST_BRAND = "ST_BRAND"
let ST_CORRECTIONS = "ST_CORRECTIONS"

let TEXT_FILE_TYPE = "Text"
let HTML_FILE_TYPE = "Html"


const QuickSightData = () => {
    const location = useLocation();
    const [progress,setProgress] = useState(false);
    const [data,setData] = useState("");
    const [fileType,setFileType] = useState("")

    const[dc,setDC] = useState("")
    const[wrapper,setWrapper] = useState("")
    const[picklist,setPickList] = useState("")
    const[tDate,setTDate] = useState("")


    useEffect(() => {
        try{
            document.getElementById("sideMenu").style.display = "none";
            getData();
        }catch (ex){
            console.log(ex.message);
        }
    }, []);

    let getData = async ()=>{
        const searchParams = new URLSearchParams(location.search);
        let params = {};

        for (let param of searchParams) {
            params[param[0]] = param[1];
        }
        console.log(params)
        try {
            setProgress(true);
            let fType = params.data_type;
            if(fType == STITCHING_VIEW){
                setFileType(HTML_FILE_TYPE);
            }else{
                setFileType(TEXT_FILE_TYPE);
            }


            let logData = await getQuicksightData(params.s3_url,params.data_type,params.customer_id,params.dc_id);
            //console.log(logData);
            if(params.s3_url){
                try {
                    console.log(params.s3_url.split("/"))
                    let s3Array = params.s3_url.split("/");
                    if(s3Array && s3Array.length>0){
                        console.log(s3Array[3])
                        console.log(s3Array[4])
                        console.log(s3Array[7])
                        console.log(s3Array[8])
                        setDC(s3Array[3]);
                        setWrapper(s3Array[4]);
                        setPickList(s3Array[7]);
                        setTDate(s3Array[8]);
                    }
                }catch (ex){
                    console.log(ex.message)
                }

            }
            if(logData && logData.data){
                if(params.data_type == HEURISTIC || params.data_type == RESPONSE || params.data_type == ST_CORRECTIONS){
                    setData(JSON.stringify(logData.data,null,4))
                }else{
                    setData(logData.data)
                }

            }
            setProgress(false);
        }catch (ex){
            console.log(ex.message)
            setProgress(false);
        }
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                <div style={{position:"absolute",top:"50%",left:"50%"}}>
                        {progress?<CircularProgress />:""}
                </div>
                <div style={{border:"1px solid #ede7e7",marginBottom:"5px"}}>
                    <Grid container spacing={2} sx={{margin:"0px"}}>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontSize"}>Distribution Center</Typography>
                        </Grid>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontSize"}>Wrapper Name</Typography>
                        </Grid>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontSize"}>Pick List Id</Typography>
                        </Grid>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontSize"}>Transaction Date</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{margin:"0px"}}>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontBold"}>{dc}</Typography>
                        </Grid>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontBold"}>{wrapper}</Typography>
                        </Grid>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontBold"}>{picklist}</Typography>
                        </Grid>
                        <Grid xs={3} lg={3} xl={3}>
                            <Typography class={"qFontBold"}>{tDate}</Typography>
                        </Grid>
                    </Grid>
                </div>

                {fileType == TEXT_FILE_TYPE?<textarea style={{width:"100%",fontSize:"14px",border:"1px solid #dfd9d9",height:(window.innerHeight-200)}} value={data} readOnly />:""}
                {fileType == HTML_FILE_TYPE? <iframe srcDoc={data} style={{width:"100%",fontSize:"14px",border:"1px solid #dfd9d9",height:(window.innerHeight-200)}} />:""}
                {fileType == HEURISTIC?<textarea style={{width:"100%",fontSize:"14px",height:(window.innerHeight-200)}} value={data} readOnly />:""}

            </div>


        </>
    )
}



export default (QuickSightData);
